@import "../../styles/palette.scss";

.catalog-wrapper{
  margin-bottom: 250px;
}

.catalog-header{
    background-image: url("../../assets/catalog_menu/background-1.png");
    background-repeat: no-repeat;
    background-size: auto;
    height: 400px;
    width: 100%;
    margin-top: 60px;
    position: absolute;
    z-index: -1;
}

.localContainer {
    max-width: 1480px;
    margin: auto;
}

.catalog {
  height: 397px;
  display: grid;
  grid-column-gap: 40px;
  margin-bottom: 5px;

  &-text {
      padding-top: 175px;
      font-family: $primaryFont;
      font-size: 64px;
      font-weight: 800;
      line-height: 64px;
      letter-spacing: -0.02em;
  }

  &-items {
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 100px;
  }

  &-item {
    height: 565px;
    background: no-repeat center center;
    background-size: cover;
    overflow: hidden;
    background-size: 100% 100%;
    transition: 0.6s ease-in-out;

    &_hovered {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 100%;

      &-title {
        font-size: 24px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $darkColor;
        background: $secondColor;
        padding: 8px 0;
        width: 100%;
        height: 40px;
        transition: 0.6s ease-in-out;
      }
    }

    &:hover {
      transition: 0.6s ease-in-out;
      background-size: 110% 110%;

      .catalog-item_hovered-title {
        background: $mainTextColor;
        height: 80px;
        transition: 0.6s ease-in-out;
      }
    }
  }
}

@media (min-width: $xl) and (max-width: 1480px) {
  .localContainer {
    margin: auto 20px auto 20px !important;
  }
}

@media (max-width: $xl) {
  .catalog {  
    grid-column-gap: 20px;
    &-item {
      height: 500px;
    }
  }

  .localContainer {
    max-width: $xl !important;
    margin: auto 20px auto 20px !important;
  }
}

@media (max-width: $lg) {
  .catalog-wrapper{
    margin-bottom: 200px;
  }

  .catalog {  
    &-item {
      height: 450px;
    }
  }
}

@media (max-width: $md) {
  .catalog-wrapper{
    margin-bottom: 140px;
  }

  .catalog {  
    &-item {
      height: 400px;
    }
  }
}

@media (max-width: $sm) {
  .catalog-wrapper{
    margin-bottom: 370px;
  }

  .catalog {  
    grid-gap: 20px;

    &-items {
      grid-template-columns: 1fr 1fr;
    }

    &_resp{
      grid-column: span 2;
      margin: 0 auto 0 auto;
      width: 426px;
    }

    &-item {
      height: 370px;
    }
  }
}

@media (max-width: $xs) {
  .catalog-wrapper{
    margin-bottom: 1050px;
  }
  

  .catalog {  
    row-gap: 20px;

    &-items {
      grid-template-columns: none !important;
      grid-template-rows: 1fr 1fr 1fr !important;
    }

    &_resp{
      grid-column: 1;
      margin-left: 0;
      margin-right: 0;
    }

    &-item {
      margin-left: auto;
      margin-right: auto;
      height: 460px !important;
      width: 360px !important;
    }
  }
  
}