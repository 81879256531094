@import "../../../styles/palette.scss";

.lastLogo {
  display: none;
}
.firstLogo {
  display: none;
}
.about_circule {
  display: none;
}

.about {
  //margin-top: 50px;
  padding-top: 70px;
  display: flex;
  width: 90%; ///
  margin: auto; ///
  h2 {
    font-size: 144px;
    font-weight: 700;
    text-transform: uppercase;
    height: fit-content;
    padding-right: 100px;
  }

  .vl {
    margin: 110px 0 0 -63px;
  }

  &-item {
    display: flex;
    margin: 80px 0 0 -50px;

    &_circule {
      width: 73px;
      height: 73px;
      border-radius: 50%;
      background: $secondColor;
    }

    &-description {
      width: 75%;
      text-transform: uppercase;
      margin-left: 57px;

      &_subtitle {
        color: $secondTextColor;
        font-size: 36px;
        line-height: 1.75;
        font-weight: 700;
      }

      &_title {
        font-size: 72px;
        font-weight: 700;
        color: $mainTextColor;
      }

      &_text {
        margin-top: 80px;
        font-size: 24px;
        color: $secondTextColor;
        line-height: 1.75;
      }

      &-withLogo {
        display: flex;
        align-items: flex-end;

        h6 {
          color: $secondTextColor;
          font-size: 36px;
          line-height: 1.9;
          margin-top: 80px;
          font-weight: 600;
        }

        img {
          height: 400px;
          width: 400px;
        }
      }
    }
  }
}

@media (max-width: $xl) {
  .about-item-description {
    margin-left: 35px;

    &_subtitle {
      font-size: 32px;
    }

    &_title {
      font-size: 64px;
    }

    &_text {
      margin-top: 50px;
    }

    &-withLogo {
      align-items: center;

      h6 {
        margin-top: 60px;
        line-height: 1.75;
      }

      img {
        width: 320px;
        height: 320px;
      }
    }
  }
}

@media (max-width: $lg) {
  .about .vl {
    margin: 121px 0 0 -83px;
  }
  .about-item_circule {
    width: 89px; ///????????
  }

  .about {
    h2 {
      padding-right: 100px;
    }
  }
  .about-item {
    margin: 80px 0 0 -48px;

    &-description {
      width: 100%;

      h5 {
        font-size: 26px;
      }

      &_text {
        font-size: 26px;
      }

      &-withLogo {
        h6 {
          font-size: 26px;
          margin-top: 30px;
          line-height: 1.5;
        }

        img {
          width: 240px;
          height: 240px;
        }
      }
    }
  }
}

@media (max-width: $md) {
  .about {
    display: block;
    h2 {
      padding-right: 0px;
      text-align: center;
    }
  }

  .about-item {
    &-description {
      overflow: hidden;
      word-break: break-word;
      text-align: center;
      background-color: $darkColor;
      margin-left: 0;
    }
  }

  .about-item_circule {
    display: none; ///????????
  }
  .about .vl {
    display: none; ///????????
  }
}

@media (max-width: $sm) {
  .lastLogo {
    display: block;
    max-width: 80%;
    margin: auto;
    margin-bottom: -295px;
    overflow: hidden;
    background-color: $darkColor;
  }

  .lastLogo img {
    width: 100%;
    //height: 310px;
    margin: auto;
  }

  .firstLogo {
    display: block;
    width: 100%;
    position: absolute;
    top: 800px;
    z-index: 1;

    overflow: hidden;
  }

  .firstLogo img {
    width: 300px;
    height: 310px;
    margin-left: 78%;
  }

  .about {
    width: 90%;
    margin: 0 auto;
    display: block; //

    &_circule {
      display: none;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: $secondColor;
      margin-top: -20px;
      position: absolute;
      left: -42px;
    }

    & .vl {
      margin: 110px 0 0 -63px;
      display: none;
      width: 35px;
      height: 2000px;
      position: absolute;
      left: 60%;
      margin-top: -120px;
      z-index: 0;
    }

    & h2 {
      width: 250px;
      margin: auto;
      font-size: 100px;
    }
  }

  .about-item:nth-child(1) {
    margin-top: 50px;
  }

  .about-item {
    width: 100%;
    margin: auto;
    margin-bottom: 100px;

    &_circule {
      display: none; ///
    }

    &-description {
      overflow: hidden;
      word-break: break-word;
      width: fit-content;
      text-align: center;
      background-color: $darkColor;
      margin-left: 0;

      &_subtitle {
        font-size: 28px;
      }

      &_text {
        font-size: 28px;
        margin-top: 35px;
      }

      &-withLogo {
        text-align: center;
        overflow: hidden;
        margin-top: 0;
      }

      &-withLogo h6 {
        font-size: 28px;
        margin-top: 35px;
      }
    }

    &-description img {
      display: none;
    }
  }
}

@media (max-width: $xs) {
  .firstLogo img {
    margin-left: 63%;
  }
  .lastLogo {
    // width: 310px;
    margin-bottom: -140px;
    background-color: $darkColor;
  }

  .about {
    width: 85%;
    margin: 0 auto;

    & .vl {
      left: 65%;
      width: 25px;
      height: 1700px;
      margin-top: -100px;
    }
  }

  .about h2 {
    font-size: 90px;
  }

  .about-item {
    flex-direction: column;
    row-gap: 160px;
    font-size: 50px;

    &-description {
      overflow: hidden;
      word-break: break-word;
      text-align: center;
      background-color: $darkColor;
      margin-left: 0;

      &_title {
        font-size: 40px;
      }

      &_subtitle {
        font-size: 18px;
      }

      &_text {
        font-size: 18px;
        margin-top: 20px;
      }

      &-withLogo {
        text-align: center;
      }

      &-withLogo h6 {
        font-size: 18px;
        margin-top: 25px;
      }

      &-description img {
        display: none;
      }

      img {
        display: none;
      }
    }
  }
}
