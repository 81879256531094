
@import "../../styles/palette.scss";

.apper_container{
    background-image: url('../../../public/assets/works_images/apper_background.png');
    width: 100%;
    //background-size: 100%;
    height: 350px;
    overflow: hidden;
    filter: blur(80px);
    z-index: -1;
    position: absolute;
  
    &-title{
        //width: 262px;
        width: 80%;//
        height: 64px;
        //padding: 178px 0 159px 180px;
        padding-top: 178px;//
        padding-bottom: 159px;//
        font-family: $primaryFont;
        font-style: normal;
        font-weight: 800;
        font-size: 64px;
        line-height: 100.4%;
        z-index: 1;
        //text-align: center;
        text-align: start; //
        margin: auto; //
        letter-spacing: -0.02em;
        text-transform: uppercase;
        background-color: transparent;
        color: $mainTextColor;
    }

}

.options{
    &_grid{
        display: grid;
        grid-template-columns: 1fr 1fr ;
        grid-template-rows: repeat(4, 1fr);
        column-gap: 10px;
        row-gap: 10px;
        width: 80%;
        //width: 100%;
        height: auto;
        margin-top: 401px;
        margin: 0 auto;
        
    }
   
    &-option{
        background-repeat: no-repeat;
        height: 340px;
        background-position: center center;
        background-size: 100% ;
        overflow: hidden;
        transition: 0.6s ease-in-out;

        &:first-child{
            grid-column-start: 1;
            grid-column-end: span 2;
        }

        &:last-child{
            grid-column-start: 1;
            grid-column-end: span 2;
        }
        
       

        &_header{
            display: flex;
            flex-direction: column;
            margin-top: 0;
            margin-left: 0;
            width: 100%;
            height: 80px;
            background-color: $mainTextColor;
            color: $darkColor;
            align-content: center;
            text-align: center;
            transition: 0.6s ease-in-out;

            &-title {
                font-size: 24px;
                text-transform: uppercase;
                text-align: center;
                margin-top: 25px;
                transition: 0.6s ease-in-out;
              }
        }

        &:hover{
            transition: 0.6s ease-in-out;
            background-size: 110%  ;
        
            .options-option_header{
                height: 40px;
                //padding: 5px 0;
                background-color: $secondColor;
                color: $darkColor;
                transition: 0.6s ease-in-out;
            }
        
            .options-option_header-title {
                margin-top: 10px;
                font-size: 24px;
                text-transform: uppercase;
                text-align: center;
                transition: 0.6s ease-in-out;
                width: 100%;
            }
        }   
    }
    }


  

    @media (max-width: $xl){
        .options-option:nth-child(4), .options-option:nth-child(7),.options-option:nth-child(8),.options-option:nth-child(9){
            background-size: 120%  100%;
        }
    }

    @media (max-width: $sm){

        .options_grid{
            row-gap: 20px;
            display: flex;
            flex-direction: column;
            width: 70%;
        }
        .options-option{
            background-size: cover;
        }
        .options-option:nth-child(4), .options-option:nth-child(7),.options-option:nth-child(8),.options-option:nth-child(9){
            background-size: cover;
        }

        .apper_container-title{
            width: 260px;
        }

        .options-option:nth-child(3):hover{
            background-size: 100%  100%;
        }

        .options-option:hover{
            background-size: 100%;
        }
    }

    @media (max-width: $xs){

        
        .apper_container-title{
            margin: auto;
            padding: 130px 0 100px 0;
        }
        .options_grid{
            // grid-template-columns: 1fr;
             row-gap: 20px;
            display: flex;
            flex-direction: column;
            width: 88%;
        }
        .options-option{
            background-size: cover;
        }

        .options-option:first-child {
            grid-column-start: 1;
            grid-column-end: 2;
                
        }
       
        .backgroundImg-offer_button{
            margin-left: 0;
            padding: 10px 0;
        }
        .backgroundImg-offer-text{
            margin-left: 50%;
        }
    }







