
@import "../../styles/palette.scss";
.backgroundImg{
    background-image: url("../../../public/assets/works_images/apper_background.png");
    background-repeat: no-repeat;
    background-size: 100%;
    height: 350px;
    padding-top: 30px;
   
  


    &-offer{
      //max-width: 1480px;

     // width: 95%;
     width: 80%;

      height: 28px;
      font-family: $primaryFont;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: 0.05em;
      text-transform: uppercase;

      margin: auto;
      @media (max-width: $xl) {
        //max-width: 1170px;
      }
    
      @media (max-width: $lg) {
        max-width: 925px;
      } 
      justify-content: flex-start;
      padding: 110px 0 0;    
     
      
    &-text {
      height: 64px;
      display: block;
      font-family: $primaryFont;
      font-size: 64px;
      font-weight: 800;
      line-height: 1;
      letter-spacing: -0.02em;
      text-transform: uppercase;
      background-color: transparent;
    }

      &_bottom{
        max-width: 1480px;
        display: flex;
        padding-top: 24px;
        flex-direction: row;
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        background-color: transparent;
        margin-top: 25px;
       
      }

      &_button{
        width: 265px;
        padding: 20px 0;
        margin-top: -20px;
        margin-left: 25px;
        background: $secondColor;
        color: $darkColor;
        text-align: center;
        align-content: center;
      }
    }
}



.offers{
  height: 307px;
  display: grid;

 width: 80%;


  margin: auto;
  grid-column-gap: 12px;

  &-top {
    grid-template-columns: 1fr 5fr 1fr;
    margin-bottom: 12px;

    .offers-item:nth-child(3){

      background-size: 155% 100% ;
    }

    .offers-item:nth-child(3):hover{
      background-size: 175% 120% ;

    }
  }

  &-bottom {
    grid-template-columns: 2fr 7fr 2fr;
    margin-bottom: 140px;
  }

  &-item  {
    background: no-repeat center center;
    background-size: 100% 100%;
    overflow: hidden;
    transition: 0.6s ease-in-out;

    &:hover {
      transition: 0.6s ease-in-out;
      background-size: 120%  120%; 
    }
  }
}
// //dealing with uneven height of pictures
.offers-top .offers-item:nth-child(1){
  background-size:170% 100%;
}

.offers-top .offers-item:nth-child(1):hover{
  background-size: 170% 120%;
}

.offers-bottom .offers-item:nth-child(1){

  background-size: 140% 100%;

}

.offers-bottom .offers-item:nth-child(1):hover{
  background-size: 170% 120%;
}

.offers-item:nth-child(2){
  background-size: 100%;
}

.offers-item:nth-child(2):hover{
  background-size: 110%;
}






//Slider

.slider {
  &-wrapper {
    display: flex;
    flex-direction: column; /////////////
    align-items: center;
    position: absolute;
    z-index: 1;
    padding-top: 30px; ///////////
    top: 0;
    width: 100%;
    background-color: rgba(52, 52, 52, 0.94);
    justify-content: flex-start;
  }

  &-container{
   // max-width: 1480px;

    width: 80%;

    height: 100%;
    top: 50%;
    @media (max-width: $xl) {
      max-width: 1170px;
    }
  
    @media (max-width: $lg) {
      max-width: 925px;
    } 
   
  }

  &-content{
    display: flex;
    align-items: center;
    flex-direction: row; /////////////
    padding-top: 350px;  /////////////// becouse background 350px

  }

  &-title{
    padding-top: 116px;
    position: absolute; /////////
    height: 64px;
    width: 192px;

    display: block;
    font-family: $primaryFont;
    font-size: 64px;
    font-weight: 800;
    line-height: 1;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    background-color: transparent;
    
  }

  &-window {
    width: 50%;  
    overflow: hidden;
    margin: auto;
    @media (max-width: $lg) {

      .slider-window{
        width: 65%;
      }
    }
    @media (max-width: $xs){
      //width: 85%;
      width: 100%;
    }
  }
  
  &-all-slides {
    height: 620px;
    display: flex;
    transition: 1.6s cubic-bezier(0.33, 0.11, 0.5, 1.03);
    @media (max-width: $sm){
     height: 505px;
    }





    .slide div {
      background: no-repeat center center;
      background-size: cover ;
      overflow: hidden;
      width: 100%;
      height: 100%;
          
         }
 
  }

  &-all-slides-3{

    height: 310px;
    display: flex;
    transition: 1.6s cubic-bezier(0.33, 0.11, 0.5, 1.03);
    @media (max-width: $sm){
      height: 252px;
     }


    .slide div {
      background: no-repeat center center;
      background-size: cover ;
      overflow: hidden;
      width: 100%;
      height: 100%;
          
         }

  }

 

  &-calculation{
    margin-top: 50px;
    row-gap: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: $primaryFont;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 100.4%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    text-align: center;

      &-link{
        font-weight: 900;
      font-size: 24px;
      width: 265px;
      padding: 20px 0;
      margin-top: -20px;
      margin-left: 25px;
      background: $secondColor;
      color: $darkColor;
      text-align: center;
      align-content: center;
      }
  }

  &-closed{
    display: none;
  }


  
}



    .slide_body-circle img{
      width: 100%;
              height: 100%;
              background: no-repeat center center;
              background-size: cover;
              overflow: hidden;
          }

  



.arrow {
  
  width: 75px;
  height: 75px;
  background: $mainTextColor;
  border-radius: 50%;
  padding: 9px;
  cursor: pointer;
  position: absolute;
  top: 55%;
  opacity: 0.3;
  transition: 0.4s ease-out;

  &:hover {
    opacity: 0.7;
    transition: 0.4s ease-out;
  }

  &-l {
    left: 11%;
    
  z-index: 6;
  }

  &-r {
    right: 11%;
        
  z-index: 6;
  }
}








@media (max-width: $xl) {

  .backgroundImg{
    height: 250px;
    

    &-offer{
      padding: 78px 0 0;
    }
  }

  .slider-container{
    top: -100px;
  
  }
  .slider-content{
    padding-top: 250px;
    
  }
  .slider-title{
    padding-top: 78px;
  }
  .arrow{
  
  }
}

@media (max-width: $lg) {
  
  .offers{
    &-top {
      grid-template-columns: 1fr 4fr 1fr;
    }
  
    &-bottom {
      grid-template-columns: 1fr 3fr 1fr;


      .offers-item{
        background-size: 165%  100%;
      }

    }
  }

  .offers-item{

    background-size: 175%  100%;
    height: 100% ;
  }

  .offers-item:nth-child(2){
    background-size: 120% ;
    
  }


  }

  .offers-item:hover{
    background-size: 190% ;
  }

  .offers-bottom .offers-item:nth-child(1){

    background-size: 192% 100%;// 282% 100%

  }

  .offers-bottom .offers-item:nth-child(1):hover{
    background-size: 255%;
  }

  .arrow{
    top: 50%;
  }

  .slider-window{

  }
  .arrow {
    &-l {
      left: 8%;
    }
  
    &-r {
      right: 8%;
    }
  }


@media (max-width: $md){

}

@media (max-width: $sm){
  .backgroundImg-offer {

    &-text{
      text-align: center;
      margin-bottom: 10px;
    }

    &_bottom{
      width: 100%;
      margin: auto;

      padding-top: 0;

      flex-direction: column;
      justify-content: center;
      row-gap: 35px;
      align-items: center;
     
    }

    &_button{
      padding: 10px 0;
    }
}



.offers-bottom .offers-item:nth-child(1){
  background-size: cover;
}

.offers-top .offers-item:nth-child(1){
  background-size: cover ;
}

.offers{

  height: 250px;
  .offers-item{
    background-size: cover;
  }

}



.slider{

  &-title{
   margin: auto;
   position: inherit;

   width: 100%;
   text-align: center;
  }

  &-content{
    padding-top: 108px;
  }

  &-container{
    width: 85%;
  }

  &-window{
    width: 100%;
  }
}
.slide:nth-child(1) div{
  background-size: 100% 190%;
}


@media (max-width: $xs) {
  .backgroundImg-offer {

    &_bottom{
      flex-direction: column;
      justify-content: center;
      row-gap: 10px;
    }

    &-text{
      width: 200px;
      margin: auto;
    }

    &_bottom p{
      font-size: 18px;
      margin: auto;
      text-align: center;
    }
    
    &_button{
      margin: auto;
      font-size: 18px;
      padding: 10px 0;
    }
    
  }
  
  .offers{
    height: auto;
    width: 85%;
    row-gap: 50px;
    margin: auto;
    &-top {
      grid-template-columns: 1fr ;
      //row-gap: 50px;
      margin-bottom: 50px;



      .offers-item:nth-child(3){

        //background-size:  100% 145%;
        background-size: cover;

      }
      
    }

    
    &-bottom{

      grid-template-columns: 1fr;

      .offers-item:nth-child(1){
        background-size: cover;

      }
      
    }
  
    &-item{
      height: 300px;
      background-size: cover!important;


      &:nth-child(1):hover{
        transition: 0s ease-in-out;
        background-size: 100% 190%; 
      } 
      &:hover {
        transition: 0s ease-in-out;
        background-size: 100% 100% ; 
      }
    }
    
    &-item:nth-child(2){
      background-size: cover;
    }
  
    &-item:nth-child(3){
      background-size: cover;

    }
  }

  .slider{

    
    &-title{
      width: 200px;
      margin: auto;
      left: 0;
      right: 0;
    }
    &-wrapper{
      position: fixed;
      height: 100vh;
    }

    &-window{
      margin-top: 0px;
    }
    &-all-slides{
      height: 300px;
    }

    &-all-slides-3{
      height: 300px;
    }

    &-calculation{
      row-gap: 20px;
      padding-top: 20px;
      width: 300px;
      text-align: center;
      margin: auto;

      &-link{
        margin: auto;
        font-size: 18px;
        padding: 10px 0;
      }
    }
  }
}


















@import "../../styles/palette.scss";

.apper_container{
    background-image: url('../../../public/assets/works_images/apper_background.png');
    width: 100%;
    //background-size: 100%;
    height: 350px;
    overflow: hidden;
    filter: blur(80px);
    z-index: -1;
    position: absolute;
  
    &-title{
        //width: 262px;
        width: 80%;//
        height: 64px;
        //padding: 178px 0 159px 180px;
        padding-top: 178px;//
        padding-bottom: 159px;//
        font-family: $primaryFont;
        font-style: normal;
        font-weight: 800;
        font-size: 64px;
        line-height: 100.4%;
        z-index: 1;
        //text-align: center;
        text-align: start; //
        margin: auto; //
        letter-spacing: -0.02em;
        text-transform: uppercase;
        background-color: transparent;
        color: $mainTextColor;
    }

}

.options{
    &_grid{
        display: grid;
        grid-template-columns: 1fr 1fr ;
        grid-template-rows: repeat(4, 1fr);
        column-gap: 10px;
        row-gap: 10px;
        width: 80%;
        //width: 100%;
        height: auto;
        margin-top: 401px;
        margin: 0 auto;
        
    }
   
    &-option{
        background-repeat: no-repeat;
        height: 340px;
        background-position: center center;
        background-size: 100% ;
        overflow: hidden;
        transition: 0.6s ease-in-out;

        &:first-child{
            grid-column-start: 1;
            grid-column-end: span 2;
        }

        &:last-child{
            grid-column-start: 1;
            grid-column-end: span 2;
        }
        
        &:nth-child(3){
            background-size: 110% ;
        }

        &_header{
            display: flex;
            flex-direction: column;
            margin-top: 0;
            margin-left: 0;
            width: 100%;
            height: 80px;
            background-color: $mainTextColor;
            color: $darkColor;
            align-content: center;
            text-align: center;
            transition: 0.6s ease-in-out;

            &-title {
                font-size: 24px;
                text-transform: uppercase;
                text-align: center;
                margin-top: 25px;
                transition: 0.6s ease-in-out;
              }
        }

        &:hover{
            transition: 0.6s ease-in-out;
            background-size: 120%  ;
        
            .options-option_header{
                height: 40px;
                //padding: 5px 0;
                background-color: $secondColor;
                color: $darkColor;
                transition: 0.6s ease-in-out;
            }
        
            .options-option_header-title {
                margin-top: 10px;
                font-size: 24px;
                text-transform: uppercase;
                text-align: center;
                transition: 0.6s ease-in-out;
                width: 100%;
            }
        }   
    }
    }

  

    @media (max-width: $xl){
        .options-option:nth-child(4), .options-option:nth-child(7),.options-option:nth-child(8),.options-option:nth-child(9){
            background-size: 120%  100%;
        }
    }
    @media (max-width: $md){
      .options-option{
        background-size: cover;

        .options-option:hover{
          background-size: cover;
        }
    }

    .options-option:nth-child(3),.options-option:nth-child(4), .options-option:nth-child(7),.options-option:nth-child(8),.options-option:nth-child(9){
      background-size: cover;
  }
    }
    @media (max-width: $sm){

        .options_grid{
            row-gap: 20px;
            display: flex;
            flex-direction: column;
            width: 70%;
        }
        .options-option{
            background-size: cover;
        }

        .options-option:nth-child(3),.options-option:nth-child(4), .options-option:nth-child(7),.options-option:nth-child(8),.options-option:nth-child(9){
            background-size: cover;
        }
        .apper_container-title{
            width: 260px;
        }
    }

    @media (max-width: $xs){

        
        .apper_container-title{
            margin: auto;
            padding: 130px 0 100px 0;
        }
        .options_grid{
            // grid-template-columns: 1fr;
             row-gap: 20px;
            display: flex;
            flex-direction: column;
            width: 88%;
        }
        .options-option{
            background-size: cover;
        }

        .options-option:first-child {
            grid-column-start: 1;
            grid-column-end: 2;
                
        }
       
        .backgroundImg-offer_button{
            margin-left: 0;
            padding: 10px 0;
        }
      
    }

  }