@import "../../styles/palette.scss";

header {
  display: flex;
  width: 100%;
  padding: 24px 0;
  position: fixed;
  background: $darkColor;
  z-index: 11;

  .logo {
    padding: 9px;
    background: $darkColor;
    border-radius: 50%;
    width: 76px;
    height: 76px;
    position: absolute;
    left: 11%;
    top: 15px;
    z-index: 1;
    @media (max-width: $xs){
      width: 60px;
      height: 60px;
    }  
  }
}

.logo svg{
  @media (max-width: $xs){
    width: 60px;
    height: 60px;
  }
}


nav {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: auto;
  @media (max-width: $sm){
    display: none;
   }
 


  a {
    text-transform: uppercase;
    font-size: 24px;
    transition: 0.4s;
    @media (max-width: $xs){
      font-size: 20px;
    }

    &:hover {
      color: $secondColor;
      transition: 0.4s;
    }
  }
}

.menu{
  display: none;
 
}

.burger_btn{
  display: none;
}




.active{
  display: flex;
  width: 40%;
  background-color: #343434c1;
  transition: 0.6s ease-in-out;
  @media (min-width: $md){
    display: none;
   }
}

.mobMenu {

 a{
  margin-bottom: 15px;
 }
}



@media (max-width: $lg) {
  header {
    .logo {
      left: 8%;
    }
  }

}
@media  (max-width: $md){
  
  header {
    .logo {
      left: 3%;
    }
  }

  nav{
    width: 69%;
  }

    .menu{
      display: none;
    }
   
  }

//@media (max-width: $xs){
  @media  (max-width: $sm){
  .menu{
    display: flex;
    width: 100%;
    align-items: center;
    text-align: center;
    position: fixed;
    top: 0px;
    right: 0px;
    padding: 10px 40px;
 
  }
  
  .mobMenu{
   position: absolute;
   top: 0;

   transition: 0.6s ease-in-out;
   padding: 97px 0px 39px 0px;

   flex-direction: column;
   width: 100%;
   margin-right: 0;
   transition-property: width;
   transition-duration: .2s;
   row-gap: 21px;
  
  }
  .burger_btn::after{
    content: " ";
    position: absolute;
    bottom: 4px;
    width: 30px;
    background-color: $secondColor;
    height: 4px;
  }
  .burger_btn::before{
    content: " ";
    position: absolute;
    top: 0;
    width: 30px;
    background-color: $secondColor;
    height: 4px;
  }
  .burger_btn{
    display: block;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 10px;
    right: 30px;
    z-index: 11;
    text-align: center;
  }
  .burger_btn span{

    content: " ";
    position: absolute;
    top: 11px;
    width: 30px;
    background-color: $secondColor;
    height: 4px;
  }
}