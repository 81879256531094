@import "../../../styles/palette.scss";

.carousel {
  &-container {
    display: flex;
    align-items: center;
    position: relative;
  }

  &-window {
    height: 100%;
    width: 100%;
    overflow: hidden;
    margin-top: 40px;
  }

  &-all-slides {
    height: 100%;
    display: flex;
    transition: 1.6s cubic-bezier(0.33, 0.11, 0.5, 1.03);
    
  }
}


//////////////////////////////////////////////////////////////////////////////////////
.slide_body-circle img{
  width: 100%;
          height: 100%;
          background: no-repeat center center;
          background-size: cover;
          overflow: hidden;
      }


    .slide_body-img{
      width: 21%;
    }
/////////////////////////////////////////////////////////////////////////////////////
.slide {
  background: no-repeat center center fixed;
  background-size: cover;
  //box-shadow: inset 0px -150px 50px -75px $darkColor;





  &_body {
    display: flex;
    margin: 150px 45px;
    justify-content: center;
    column-gap: 60px;
    align-items: center;

    position: relative;
    z-index: 1;

    &-circle {
      position: absolute;
      z-index: -1;

      
      svg {
        width: 632px;
        height: 632px;
      }

      img {
        width: 281px;
        height: 281px;
        position: absolute;
        display: none;
        
      }
    }

    &-text {
      text-transform: uppercase;
      margin-top: 140px;

      &_firstMt {
        margin-top: -66px;
      }

      h2 {
        font-size: 162px;
        font-weight: 700;
        line-height: 100.4%;


       letter-spacing: -0.02em;
       text-transform: uppercase;
     

        span {
          margin-left: 0.7em;
        }
      }

      h4 {
        font-size: 40px;
        color: $darkColor;
        text-align: right;
        margin-right: 10px;
      }
    }
  }

  &:nth-child(1) {
    background-image: url("../../../assets/slides/slide-1.png");
  }

  &:nth-child(2) {
    background-image: url("../../../assets/slides/slide-2.png");
  }
}

.arrow {
  width: 75px;
  height: 75px;
  background: $mainTextColor;
  border-radius: 50%;
  padding: 9px;

  cursor: pointer;
  position: absolute;
  top: 55%;

  z-index: 1;
  opacity: 0.3;
  transition: 0.4s ease-out;

  &:hover {
    opacity: 0.7;
    transition: 0.4s ease-out;
  }

  &-L {
    left: 11%;
  }

  &-R {
    right: 11%;
  }
}

@media (max-width: $xl) {
  .slide {
    &_body {
      justify-content: space-around;
      margin: 114px 30px;
      column-gap: 0;
      
      &-text {
        margin-top: 170px;

        &_firstMt {
          margin-top: -30px;
        }

        h2 {
          font-size: 160px;
        }

        h4 {
          font-size: 32px;
          margin-right: 7px;
        }
      }

      &-img {
        width: 25%;
        height: 25%;
      }

      &-circle {
        margin-top: 50px;

        svg {
          width: 490px;
          height: 490px;
        }
      }
    }
  }
}

@media (max-width: $lg) {
  .arrow {
    &-L {
      left: 8%;
    }
  
    &-R {
      right: 8%;
    }
  }

  .slide {
    &_body {
      &-text {
        margin-top: 144px;

        &_firstMt {
          margin-top: -85px;;
        }
        h2 {
          font-size: 125px;
          font-weight: 650;
        }
        h4 {
          font-size: 28px;
        }
      }

      &-img {
        width: 25%;
        margin-top: -112px !important;
      }

      &-circle {
        margin-top: 25px;

        svg {
          width: 400px;
          height: 400px;
        }
      }
    }
  }
}


@media (max-width: $md){
  .slide {
    &_body {
      margin: 83px 55px;
    }

}
}

@media (max-width: $sm){
    .slide {
    &_body {
      margin: 83px 50px;
      &-text {
        margin-top: 220px;
        width: 300px;
        z-index: 9;
        margin-right: -100px;

        &_firstMt {
          margin-top: -25px;
          
        }
        h2 {
          font-size: 105px;
          margin-top: -60px;
        }
        h4{
          margin-right: -102px;
          margin-top: 25px;
        }
       
      }
      
      

     

      &-img{
        margin: -190px 0px 0px 0px !important;
        width: 31%;
      }
      
    }
    
}
  .arrow{
    display: none;
  }
}

@media (max-width: $xs){
  .slide {
    &_body {

      margin: 83px 0px;

      &-text {
        margin-top: 0px;
        padding-top: 350px;
        width: 240px;
        z-index: 9;

        &_firstMt {
          margin-top: -85px;;
        }
        h2 {
          font-size: 95px;

          span {
            margin-left: 0.6em;
          }
        }
        h4{
          margin-right: -105px;
          margin-top: 25px;
          color: $secondTextColor;
         
        }
       
      }
      
      &-circle{
        display: none;
      }

     

      &-img{
        margin: -270px 00px 0px 0px !important;
        //width: 210px;
        width: 49%;
      }
      
    }
    
}
  .arrow{
    display: none;
  }

}