@import "../../../styles/palette.scss";

.services {
  height: 397px;
  display: grid;
  grid-column-gap: 14px;

  padding-top: -200px;

  &-top {
    grid-template-columns: 1fr 5fr 1fr;
    padding-bottom: 14px;

    .services-item:nth-child(3){
      background-size: 135% 100%;
    }
  }

  &-bottom {
    grid-template-columns: 2fr 7fr 2fr;
  }

  &-item {
    background: no-repeat center center;
    background-size: cover;
    overflow: hidden;
    background-size: 100% 100%;
    transition: 0.6s ease-in-out;

    &:nth-child(1){
      background-size: 135% 100%;
    }

    &_hovered {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 100%;
   
      
      

      &-title {
        font-size: 24px;
        text-transform: uppercase;
        text-align: center;
        color: $darkColor;
        background: $secondColor;
        padding: 8px 0;
        width: 100%;

        margin-top: -40px;
        transition: 0.6s ease-in-out;

        &-act{
          margin-top: 0;
        }
      }

      &-icon {
        padding: 20.5px 22px;
        border-radius: 50%;
        background: $secondColor;

        margin-bottom: -80px;
        transition: 0.6s ease-in-out;
      }
    }

    &:hover {
      transition: 0.6s ease-in-out;
      background-size: 110% 110%;

      .services-item_hovered-title {
        margin-top: 0;
        transition: 0.6s ease-in-out;
      }

      .services-item_hovered-icon {
        margin-bottom: 15px;
        transition: 0.6s ease-in-out;
      }
    }
  }
}

.servicesLinks {
  display: flex;
  justify-content: space-between;
  margin: 170px 0 130px;

  a {
    font-size: 40px;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 2;
    transition: 0.4s;

    &:nth-child(2) {
      text-align: center;
    }

    &:nth-child(3) {
      text-align: right;
    }

    &:hover {
      color: $secondColor;
      transition: 0.4s;
    }
  }
}


@media (min-width: $xl){
  .services{
    width: 90%;
    margin: 0 auto;
  }

  .servicesLinks{
    width: 90%;
    margin: 0 auto;
    padding: 170px 0 130px;
  }
  
}
@media (max-width: $lg) {
  .services {
    height: 307px;
    
    &-top {
      grid-template-columns: 1fr 4fr 1fr;
      padding-bottom: 0;
    }
  
    &-bottom {
      grid-template-columns: 1fr 3fr 1fr;
    }
  }

  .servicesLinks a {
    font-size: 32px;
    line-height: 1.5;
  }
}

@media (max-width: $lg){
  .services{
    grid-template-columns: 1fr;
    height: 1250px;
    background-size: cover;
    row-gap: 20px;

    &-top{
      margin-bottom: 20px;
      .services-item:nth-child(3){
        background-size: cover;
      }
    }

    &-item{
      background-size: cover;

      &:nth-child(1){
        background-size: cover;
      }
    }
  }

  .servicesLinks{
    flex-direction: column;
    row-gap: 35px;
  }

  .servicesLinks a:nth-child(1){
    text-align: center;
  }

  .servicesLinks a:nth-child(3){
    text-align: center;
  }
}
//
.passsive{
  display: none;
  touch-action:pan-y;
}

.services-mobitem{
  transition: 0.6s ease-in-out;
  background-size: 110% 110%;
  
}



@media (max-width: $md){

  .services{
    width: 90%;
    margin: auto;
  }

  .services-bottom{
    padding-top: 20px;

  }


  .servicesLinks a {
    font-size: 38px;
    line-height: 1.5;
    
  }
}
@media (max-width: $sm){
  .services-bottom{
    padding-top: 20px;
  }
  .servicesLinks a{
    font-size: 28px;
  }
  .services-item:hover{
    background-size: cover;
  }
}