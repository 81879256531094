@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@600;700;800;900&family=Roboto:wght@400;500&display=swap");
@import "./palette.scss";

* {
  color: inherit;
  margin: 0;
  padding: 0;
  line-height: 1;
}

body {
  font-family: "Raleway", sans-serif;
  // font-family: 'Roboto', sans-serif;
  background: $darkColor;
  color: $mainTextColor;

  scrollbar-color: $mainTextColor rgba(255, 255, 255, 0.1);
  scrollbar-width: 9px;
  &::-webkit-scrollbar {
    width: 9px;
    background-color: rgba(255, 255, 255, 0.1);
  }
  &::-webkit-scrollbar-thumb {
    background-color: $mainTextColor;
    // border-radius: 4px;
  }
}

button {
  border: none
}

input {
  border: none;
}

a {
  text-decoration: none;
}

.container {
  max-width: 1480px;
  margin: auto;
 //overflow: hidden;
/////////////////////////////


/////////////////////////////
  @media (max-width: $xl) {
    max-width: 1170px;
  }

  @media (max-width: $lg) {
    max-width: 925px;
  }

  @media (max-width: $md) {
    max-width: 825px;
  }

  @media (max-width: $xs) {
    max-width: 440px;
  }
}

.vl {
  border-left: 25px solid rgba(255, 255, 255, 0.3);
}






