@import "../../styles/palette.scss";

.wrapper-kitchen {
    margin-bottom: 350px;
}

.header {
    height: 55px;
    width: 100%;
}

.localContainer {
    max-width: 1480px;
    margin: auto;
}

.calc-header {
    background-image: url("../../assets/catalog_menu/background-1.png");
    background-repeat: no-repeat;
    background-size: auto;
    height: 400px;
    width: 100%;
    position: absolute;
    z-index: -1;
}

.calc {
    height: 397px;
    display: grid;
    grid-column-gap: 40px;
    margin-bottom: 5px;
    font-family: $primaryFont;
    font-weight: 600;

    &-title {
        height: 64px;
        width: 750px;
        padding-top: 145px;
        font-size: 64px;
        font-weight: 800;
        line-height: 64px;
        letter-spacing: -0.02em;
    }

    &-text {
        padding-top: 23px;
        font-size: 14px;
        line-height: 163.9%;
        letter-spacing: 0.02em;
    }

    &-items {
        grid-template-columns: 1fr 1fr;
    }

    &-calc_block_kitchen {
        margin-top: 50px;
        height: 666px;
        background: rgba(201, 173, 152, 0.05);
    }

    &-calc_titles {
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 0.05em;
        text-align: right;
        margin: 20px 84px 26px 0;
    }

    &-img_kitchen {
        height: 666px;
        max-width: 767px;
        width: 100%;
    }

    &-img_kitchen_block {
        margin-top: 35px;
        height: 666px;
        max-width: 667px;
        width: 100%;
    }
}

.area {
    display: grid;
    grid-row-gap: 5px;
    grid-template-columns: 3fr 3fr 1fr;
    height: 56px;
    margin: 0 80px 0 80px;
    align-items: center;
    font-family: $primaryFont;
    font-weight: 600;

    &-title_block {
        background-color: $secondMainTextColor;
        height: 56px;
        display: flex;
        align-items: center;
    }

    &-title {
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.05em;
        color: $darkColor;
        margin-left: 26px;
    }

    &-input {
        width: 100%;
        height: 56px;
        text-align: center;
        background-color: $unhoveredColor;
        color: $darkColor;
        border: none;
        margin-left: auto;
        font-size: 24px;
        line-height: 21px;
        letter-spacing: 0.05em;
    }
    &-input::placeholder {
        text-align: center;
        color: $darkColor;
    }

    &-size_block {
        background-color: $secondMainTextColor;
        height: 56px;
        display: flex;
        align-items: center;
        margin-left: 5px;
    }

    &-size {
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.05em;
        color: $darkColor;
        margin-left: 26px;
        margin: 0 auto 0 auto;
    }
}

.total-price {
    display: grid;
    grid-template-columns: 3fr 5fr;
    margin: 7px 80px 0 80px;
    height: 56px;
    background-color: $secondMainTextColor;
    font-family: $primaryFont;
    font-weight: 600;

    &-text {
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.05em;
        color: $darkColor;
        display: flex;
        align-items: center;
        margin-left: 26px;
    }

    &-currency {
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.05em;
        color: $darkColor;
        display: flex;
        align-items: center;
        margin-left: 5px;
        padding-top: 3px;
    }

    &-result {
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 0.05em;
        color: $darkColor;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.contact-info {
    display: grid;
    grid-template-columns: 2fr 3fr;
    margin: 50px 80px 0 80px;
    font-family: $primaryFont;
    font-weight: 600;

    &-block {
        height: 56px;
        border: 1px solid $mainTextColor;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-text {
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.05em;
        color: $mainTextColor;
    }

    &-input {
        background-color: transparent;
        width: 100%;
        height: 100%;
        border: none;
        text-align: center;
        font-size: 20px;
        line-height: 21px;
        letter-spacing: 0.05em;
    }
}

.submit-button {
    display: block;
    margin: 70px 80px 0 auto;
    width: 295px;
    height: 56px;
    background-color: $secondMainTextColor;
    border: none;
    color: $darkColor;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.05em;
    cursor: pointer;
}

.error-message {
    color: $errorMessage;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.05em;
    margin-left: 80px;
}

@media (max-width: $xl) {
    .wrapper-kitchen {
        margin-bottom: 400px !important;
    }

    .calc {
        &-img {
            height: 740px !important;
        }
    }

    .material {
        &-price {
            font-size: 18px !important;
        }
    }
}

@media (max-width: $lg) {
    .wrapper-kitchen {
        margin-bottom: 400px !important;
    }

    .calc {
        &-title {
            width: auto !important;
            font-size: 44px !important;
        }

        &-items {
            grid-template-columns: 1fr !important;
        }

        &-img_kitchen {
            display: none;
        }
    }
}

@media (max-width: $sm) {
    .area {
        &-title {
            margin-left: 10px !important;
        }
    }

    .calc {
        &-title {
            width: auto !important;
            font-size: 36px !important;
        }
    }

    .material {
        display: flex;
        margin: 0 80px 0 80px;
        height: 56px;
        background-color: $unhoveredColor;
        align-items: center;
        font-family: $primaryFont;
        font-weight: 600;

        &-radio {
            &:hover {
                margin: 0 0 0 15px !important;
            }

            &:checked {
                margin: 0 0 0 15px !important;
            }
        }

        &-name {
            margin-left: 10px !important;
        }

        &-price {
            margin-right: 15px !important;
            font-size: 18px !important;
        }
    }
}

@media (max-width: $xs) {
    .calc {
        height: 397px;
        display: grid;
        grid-column-gap: 40px;
        margin-bottom: 5px;
        font-family: $primaryFont;
        font-weight: 600;

        &-title {
            width: auto !important;
            font-size: 44px !important;
        }

        &-text {
            padding-top: 63px !important;
        }

        &-calc_titles {
            margin: 20px 0 26px 20px !important;
            text-align: left !important;
        }
    }

    .material {
        margin: 0 20px 0 20px !important;

        &-name {
            font-size: 18px !important;
        }

        &-price {
            font-size: 18px !important;
            line-height: 24px !important;
        }
    }

    .area {
        margin: 0 20px 0 20px !important;

        &-title {
            margin-left: 15px !important;
        }
    }

    .total-price {
        margin: 7px 20px 0 20px !important;
    }

    .contact-info {
        margin: 50px 20px 0 20px !important;
    }

    .error-message {
        margin-left: 20px !important;
    }

    .submit-button {
        margin: 40px 20px 10px 20px !important;
    }
}
